<template>
  <div class="privacy">
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Microsoft JhengHei'"
        >洋葱日语用户隐私声明</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">发布日期</span
      ><span style="font-family: 'Courier New'">：2022</span
      ><span style="font-family: 'Microsoft JhengHei'">年</span
      ><span style="font-family: 'Courier New'">7</span
      ><span style="font-family: 'Microsoft JhengHei'">月</span
      ><span style="font-family: 'Courier New'">30</span
      ><span style="font-family: 'Microsoft JhengHei'">日</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">生效日期</span
      ><span style="font-family: 'Courier New'">：2022</span
      ><span style="font-family: 'Microsoft JhengHei'">年</span
      ><span style="font-family: 'Courier New'">7</span
      ><span style="font-family: 'Microsoft JhengHei'">月</span
      ><span style="font-family: 'Courier New'">30</span
      ><span style="font-family: 'Microsoft JhengHei'">日</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">引言</span
      ><span style="font-family: 'Courier New'">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'">   </span
      ><span style="font-family: 'Microsoft JhengHei'">欢迎您使用洋葱日语</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >洋葱日语及旗下网站和软件</span
      ><span style="font-family: 'Courier New'">（</span
      ><span style="font-family: 'Microsoft JhengHei'">以下简称</span
      ><span style="font-family: 'Courier New'">“</span
      ><span style="font-family: 'Microsoft JhengHei'">洋葱日语</span
      ><span style="font-family: 'Courier New'">”</span
      ><span style="font-family: 'Microsoft JhengHei'">或</span
      ><span style="font-family: 'Courier New'">“</span
      ><span style="font-family: 'Microsoft JhengHei'">我们</span
      ><span style="font-family: 'Courier New'">”）</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >由湖南洋葱教育科技有限公司负责管理</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >洋葱日语对每一位用户的隐私权采取高度尊重且保护的态度</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">为给您提供更准确</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >有个性化的服务和更安全的互联网环境</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">我们依据</span
      ><span style="font-family: 'MS Gothic'">《</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >中华人民共和国网络安全法</span
      ><span style="font-family: 'MS Gothic'">》、《</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >信息安全技术个人信息安规范</span
      ><span style="font-family: 'MS Gothic'">》</span
      ><span style="font-family: 'Courier New'">（GB/T35273-2017）</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >以及其他相关法律法规和技术规范</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">将</span
      ><span style="font-family: 'Courier New'">“</span
      ><span style="font-family: 'Microsoft JhengHei'">用户隐私</span
      ><span style="font-family: 'Courier New'">”</span
      ><span style="font-family: 'Microsoft JhengHei'">相关内容以本</span
      ><span style="font-family: 'MS Gothic'">《</span
      ><span style="font-family: 'Microsoft JhengHei'">隐私政策</span
      ><span style="font-family: 'MS Gothic'">》</span
      ><span style="font-family: 'Microsoft JhengHei'">的形式进行发布</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'">   </span
      ><span style="font-family: 'Microsoft JhengHei'"
        >本政策适用于我们的所有产品及服务</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >本政策与您所使用的我们的产品或服务息息相关</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">您在下载</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">安装</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">启动</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">浏览</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">注册</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">登录</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >使用洋葱日语的产品或服务时</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >我们将按照本政策的约定处理和保护您的个人信息</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'">   </span
      ><span style="font-family: 'Microsoft JhengHei'"
        >您在使用洋葱日语的产品或服务时</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >洋葱日语可能会收集和使用您的相关信息</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Microsoft JhengHei'">为此</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">希望通过本</span
      ><span style="font-family: 'MS Gothic'">《</span
      ><span style="font-family: 'Microsoft JhengHei'">隐私政策</span
      ><span style="font-family: 'MS Gothic'">》</span
      ><span style="font-family: 'Microsoft JhengHei'">向您说明</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">洋葱日语如何取得</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">使用</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">分享</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">保存和管理这些信息</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >请在使用或继续使用我们的各项产品与服务前</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >仔细阅读并充分理解本政策</span
      ><span style="font-family: 'Courier New'">（</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >尤其是加粗提示的内容</span
      ><span style="font-family: 'Courier New'">），</span
      ><span style="font-family: 'Microsoft JhengHei'">并在需要时</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">按照本政策的指引</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">作出适当的选择</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'">   </span
      ><span style="font-family: 'Microsoft JhengHei'"
        >您使用或继续使用洋葱日语服务</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >都表示您同意我们按照本</span
      ><span style="font-family: 'MS Gothic'">《</span
      ><span style="font-family: 'Microsoft JhengHei'">隐私政策</span
      ><span style="font-family: 'MS Gothic'">》</span
      ><span style="font-family: 'Microsoft JhengHei'">收集</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">使用</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">储存和分享您的信息</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'">   </span
      ><span style="font-family: 'Microsoft JhengHei'">本</span
      ><span style="font-family: 'MS Gothic'">《</span
      ><span style="font-family: 'Microsoft JhengHei'">隐私政策</span
      ><span style="font-family: 'MS Gothic'">》</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >将帮助您了解以下内容</span
      ><span style="font-family: 'Courier New'">：</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">目录</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">第一章</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'"
        >如何收集您的个人信息</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">第二章</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'"
        >如何使用您的个人信息</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">第三章</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">如何分享</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >转让或披露您的的个人信息</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">第四章</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">如何使用</span
      ><span style="font-family: 'Courier New'">Cookie</span
      ><span style="font-family: 'Microsoft JhengHei'">和同类技术</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">第五章</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'"
        >如何保护您的个人信息</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">第六章</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'"
        >如何保存您的个人信息</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">第八章</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">如何管理</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >查看或删除您的个人信息</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">第九章</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">投诉及建议</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">第十章</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">本政策如何更新</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">第十一章</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">其他</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">第一章</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'"
        >如何收集您的个人信息</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">定义</span
      ><span style="font-family: 'Courier New'">：</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'">   </span
      ><span style="font-family: 'Microsoft JhengHei'">根据</span
      ><span style="font-family: 'Courier New'">GB/T35273</span
      ><span style="font-family: 'MS Gothic'">《</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >信息安全技术个人信息安全规范</span
      ><span style="font-family: 'MS Gothic'">》</span
      ><span style="font-family: 'Microsoft JhengHei'">附录</span
      ><span style="font-family: 'Courier New'">A（</span
      ><span style="font-family: 'Microsoft JhengHei'">资料性附录</span
      ><span style="font-family: 'Courier New'">）-</span
      ><span style="font-family: 'Microsoft JhengHei'">个人信息示例</span
      ><span style="font-family: 'Courier New'">-</span
      ><span style="font-family: 'Microsoft JhengHei'">表</span
      ><span style="font-family: 'Courier New'">A.1，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息</span
      ><span style="font-family: 'Courier New'">， </span
      ><span style="font-family: 'Microsoft JhengHei'">如姓名</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">出生日期</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">身份证件号码</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">个人生物识别信息</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">住址</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">通信通讯联系方式</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">通信记录和内容</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">账号密码</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">财产信息</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">征信信息</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">行踪轨迹</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">住宿信息</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">健康生理信息</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">交易信息等</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'">   </span
      ><span style="font-family: 'Microsoft JhengHei'">根据</span
      ><span style="font-family: 'Courier New'">GB/T35273</span
      ><span style="font-family: 'MS Gothic'">《</span
      ><span style="font-family: 'Microsoft JhengHei'">个人信息安全规范</span
      ><span style="font-family: 'MS Gothic'">》</span
      ><span style="font-family: 'Microsoft JhengHei'">中附录</span
      ><span style="font-family: 'Courier New'">B（</span
      ><span style="font-family: 'Microsoft JhengHei'">资料性附录</span
      ><span style="font-family: 'Courier New'">）-</span
      ><span style="font-family: 'Microsoft JhengHei'">个人敏感信息判定</span
      ><span style="font-family: 'Courier New'">-</span
      ><span style="font-family: 'Microsoft JhengHei'">表</span
      ><span style="font-family: 'Courier New'">B.1，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >个人敏感信息是指一旦泄露</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >非法提供或滥用可能危害人身和财产安全</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">极易导致个人名誉</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >身心健康受到损害或歧视性待遇等的个人信息</span
      ><span style="font-family: 'Courier New'">， </span
      ><span style="font-family: 'Microsoft JhengHei'">如个人财产信息</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">个人健康生理信息</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">个人生物识别信息</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">个人身份信息</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >网络身份标识信息及其他信息</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">一</span
      ><span style="font-family: 'Courier New'">.</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >我们将通过以下途径收集和获得您的信息</span
      ><span style="font-family: 'Courier New'">：</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> 1</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">您提供的信息</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Microsoft JhengHei'">例如</span
      ><span style="font-family: 'Courier New'">：</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> （1）</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >您在注册洋葱日语服务的帐号或使用洋葱日语服务时</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">向我们提供的信息</span
      ><span style="font-family: 'Courier New'">；</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> （2）</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >您通过洋葱日语服务向其他方提供的共享信息</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >以及您使用洋葱日语服务时所储存的信息</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> 2</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >其他方分享的您的信息</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >亦即其他方使用洋葱日语服务时所提供有关您的共享信息</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> 3</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">我们获取的您的信息</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >您在使用洋葱日语服务时</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">我们收集</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">汇总</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">记录的信息</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">例如日志信息</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">位置信息</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">设备信息等</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">二</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">我们会出于以下目的</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >收集和使用您以下类型的信息</span
      ><span style="font-family: 'Courier New'">：</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> 1</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >帮助您完成注册及登录</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> （</span
      ><span style="font-family: 'Microsoft JhengHei'">用于</span
      ><span style="font-family: 'Courier New'">APP</span
      ><span style="font-family: 'Microsoft JhengHei'">登录</span
      ><span style="font-family: 'Courier New'">/</span
      ><span style="font-family: 'Microsoft JhengHei'">信息传播管理</span
      ><span style="font-family: 'Courier New'">/</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >同步洋葱日语网站登录信息等合法途径</span
      ><span style="font-family: 'Courier New'">）</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> （1）</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >为便于我们为您提供服务</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >您需要提供基本注册或登录信息</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">包括手机号码</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">电子邮箱地址</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">并创建您的账号</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">用户名和密码</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Microsoft JhengHei'">在部分单项服务中</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">如果您仅需使用浏览</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">搜索等基本服务</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >您不需要注册成为洋葱日语用户及提供上述信息</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> （2）</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >在部分单项服务注册及登录过程中</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >如果您提供其他额外信息</span
      ><span style="font-family: 'Courier New'">（</span
      ><span style="font-family: 'Microsoft JhengHei'">例如您的昵称</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">性别</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">籍贯</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">出生年月日</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">头像</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">兴趣爱好</span
      ><span style="font-family: 'Courier New'">），</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >将有助于我们给您提供个性化的推荐和更优的服务体验</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >但如果您不提供这些信息</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >并不会影响您使用洋葱日语服务的基本功能</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">（3）</span
      ><span style="font-family: 'Microsoft JhengHei'">此外</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >在符合洋葱日语单项服务的服务协议约定条件及国家相关法律法规规定的情况下</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >我们也提供账号注销的服务</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">您可以在我的</span
      ><span style="font-family: 'Courier New'">-</span
      ><span style="font-family: 'Microsoft JhengHei'">个人中心</span
      ><span style="font-family: 'Courier New'">-</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >账号注销完成您的账号注销</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >我们收到后会立即处理</span
      ><span style="font-family: 'Courier New'">(24</span
      ><span style="font-family: 'Microsoft JhengHei'">小时之内</span
      ><span style="font-family: 'Courier New'">)，</span
      ><span style="font-family: 'Microsoft JhengHei'">注销成功后</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >洋葱日语将停止使用并删除您的个人信息</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> 2</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">向您提供商品或服务</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> （</span
      ><span style="font-family: 'Microsoft JhengHei'">用于</span
      ><span style="font-family: 'Courier New'">APP</span
      ><span style="font-family: 'Microsoft JhengHei'">活动</span
      ><span style="font-family: 'Courier New'">/</span
      ><span style="font-family: 'Microsoft JhengHei'">资料领取</span
      ><span style="font-family: 'Courier New'">/</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >同步日语学习辅助服务等合法途径</span
      ><span style="font-family: 'Courier New'">）</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'">   </span
      ><span style="font-family: 'Microsoft JhengHei'"
        >我们所收集和使用的信息是为您提供洋葱日语服务的必要条件</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">如缺少相关信息</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >我们将无法为您提供洋葱日语服务的核心内容</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">例如</span
      ><span style="font-family: 'Courier New'">：</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> （1）</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >您在使用洋葱日语服务时</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">上传和</span
      ><span style="font-family: 'Courier New'">/</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >或发布信息以及进行相关行为</span
      ><span style="font-family: 'Courier New'">（</span
      ><span style="font-family: 'Microsoft JhengHei'">例如发布跟贴</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">回复跟贴</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">顶贴</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">喜欢文章</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">发布讲讲</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">分享</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">收藏</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">打赏</span
      ><span style="font-family: 'Courier New'">）</span
      ><span style="font-family: 'Microsoft JhengHei'">时</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">我们将收集您上传</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">发布或形成的信息</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">并有权展示您的昵称</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">头像和发布内容</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> （2）</span
      ><span style="font-family: 'Microsoft JhengHei'">在部分服务项目中</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >为便于向您交付商品或服务</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >您至少需提供收货人个人身份信息</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">姓名</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">收货地址</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">邮政编码</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">收货人</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">联系电话</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">支付状态信息</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >如果您拒绝提供此类信息</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >我们将无法完成相关交付服务</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >如您通过洋葱日语服务为其他人订购商品或服务</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >您需要提供该实际订购人的前述信息</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >向我们提供该实际订购人的前述信息之前</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >您需确保您已经取得其授权同意</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> （3）</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >为展示您账户的订单信息</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >我们会收集您在使用洋葱日语服务过程中产生的订单信息用于向您展示及便于您对订单进行管理</span
      ><span style="font-family: 'Courier New'">；</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> （4）</span
      ><span style="font-family: 'Microsoft JhengHei'">当您与我们联系时</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >我们可能会保存您的通信</span
      ><span style="font-family: 'Courier New'">/</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >通话记录和内容或您留下的联系方式等信息</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >以便与您联系或帮助您解决问题</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >或记录相关问题的处理方案及结果</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> （5）</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >为确认交易状态及为您提供售后与争议解决服务</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >我们会通过您基于交易所选择的交易对象</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">支付机构</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >物流公司等收集与交易进度相关的您的交易</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">支付</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">物流信息</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >或将您的交易信息共享给上述服务提供者</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> （6）</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >为向您提供更契合您需求的页面展示和推荐个性化内容</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">进行用户画像</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">了解产品适配性</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">识别账号异常状态</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >我们可能会收集关于您使用的服务以及使用方式的信息并将这些信息进行关联</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">这些信息包括</span
      ><span style="font-family: 'Courier New'">：</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> ①</span
      ><span style="font-family: 'Microsoft JhengHei'">日志信息</span
      ><span style="font-family: 'Courier New'">：</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >当您使用我们的服务时</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >我们可能会自动收集您对我们服务的详细使用情况</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >作为有关网络日志保存</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Microsoft JhengHei'">例如您的登录账号</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">搜索查询内容</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Courier New'">IP</span
      ><span style="font-family: 'Microsoft JhengHei'">地址</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">浏览器的类型</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">电信运营商</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">网络环境</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">使用的语言</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >访问日期和时间及您访问的网页浏览记录</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Courier New'">Push</span
      ><span style="font-family: 'Microsoft JhengHei'">打开记录</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">停留时长</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">刷新记录</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">发布记录</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">关注</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">订阅</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">收藏及分享</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> ②</span
      ><span style="font-family: 'Microsoft JhengHei'">设备信息</span
      ><span style="font-family: 'Courier New'">：</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >我们可能会根据您在软件安装及使用中授予的具体权限</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >接收并记录您所使用的设备相关信息</span
      ><span style="font-family: 'Courier New'">（</span
      ><span style="font-family: 'Microsoft JhengHei'">例如</span
      ><span style="font-family: 'Courier New'">IMEI</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Courier New'">MAC</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Courier New'">Serial</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Courier New'">SIM</span
      ><span style="font-family: 'Microsoft JhengHei'">卡</span
      ><span style="font-family: 'Courier New'">IMSI</span
      ><span style="font-family: 'Microsoft JhengHei'">识别码</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">设备机型</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">操作系统及版本</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">客户端版本</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">设备分辨率</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">包名</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">设备设置</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">进程及软件列表</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">唯一设备标识符</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">软硬件特征信息</span
      ><span style="font-family: 'Courier New'">）</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >设备所在位置相关信息</span
      ><span style="font-family: 'Courier New'">（</span
      ><span style="font-family: 'Microsoft JhengHei'">例如</span
      ><span style="font-family: 'Courier New'">IP </span
      ><span style="font-family: 'Microsoft JhengHei'">地址</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Courier New'">GPS</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >位置以及能够提供相关信息的</span
      ><span style="font-family: 'Courier New'">WLAN</span
      ><span style="font-family: 'Microsoft JhengHei'">接入点</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >蓝牙和基站传感器信息</span
      ><span style="font-family: 'Courier New'">）</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'">   </span
      ><span style="font-family: 'Microsoft JhengHei'">请注意</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">单独的设备信息</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >日志信息是无法识别特定自然人身份的信息</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >或者将其与个人信息结合使用</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">则在结合使用期间</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >这类非个人信息将被视为个人信息</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >除取得您授权或法律法规另有规定外</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >我们会将该类个人信息做匿名化</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">去标识化处理</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> （7）</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >我们通过间接方式收集到的您的信息</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'">   </span
      ><span style="font-family: 'Microsoft JhengHei'">我们可能从关联方</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >第三方合作伙伴获取您授权共享的相关信息</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Microsoft JhengHei'">例如</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >我们可能从第三方获取您授权共享的账户信息</span
      ><span style="font-family: 'Courier New'">（</span
      ><span style="font-family: 'Microsoft JhengHei'">头像</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">昵称</span
      ><span style="font-family: 'Courier New'">）</span
      ><span style="font-family: 'Microsoft JhengHei'">并在您同意本</span
      ><span style="font-family: 'MS Gothic'">《</span
      ><span style="font-family: 'Microsoft JhengHei'">隐私政策</span
      ><span style="font-family: 'MS Gothic'">》</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >后将您的第三方账户与您的洋葱日语服务账户绑定</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >使您可以通过第三方账户直接登录并使用我们的产品与</span
      ><span style="font-family: 'Courier New'">/</span
      ><span style="font-family: 'Microsoft JhengHei'">或服务</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >我们将在符合相关法律法规规定</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >并依据与关联方或第三方合作伙伴的约定</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >确信其提供的信息来源合法的前提下</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >收集并使用您的这些信息</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> （8）</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >基于上述您向我们提供的信息</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >我们可能收集的信息及我们通过间接方式收集到的您的信息</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >我们可能会基于上述一项或几项信息的结合</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">进行推荐算法建模</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >程序化广告推荐算法建模</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >用户行为分析及用户画像</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >以便向您提供更契合您需求的页面展示和推荐个性化内容</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> 3</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">向您推送消息</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> （</span
      ><span style="font-family: 'Microsoft JhengHei'">用于</span
      ><span style="font-family: 'Courier New'">APP</span
      ><span style="font-family: 'Microsoft JhengHei'">活动推送</span
      ><span style="font-family: 'Courier New'">/</span
      ><span style="font-family: 'Microsoft JhengHei'">用户之间的互动通知</span
      ><span style="font-family: 'Courier New'">/</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >学习状态题型等合法途径</span
      ><span style="font-family: 'Courier New'">）</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> （1）</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >为您展示和推送商品或服务</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >我们可能使用您的信息</span
      ><span style="font-family: 'Courier New'">（</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >例如您的浏览及搜索记录</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">设备信息</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">位置信息</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">订单信息</span
      ><span style="font-family: 'Courier New'">），</span
      ><span style="font-family: 'Microsoft JhengHei'">用于提取您的浏览</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">搜索偏好</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">行为习惯</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">位置信息相关特征</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >并基于特征标签通过电子邮件</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >短信或其他方式向您发送营销信息</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >提供或推广我们或第三方的如下商品和服务</span
      ><span style="font-family: 'Courier New'">：</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> ①</span
      ><span style="font-family: 'Microsoft JhengHei'">我们的商品和服务</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">包括但不限于</span
      ><span style="font-family: 'Courier New'">：</span
      ><span style="font-family: 'Microsoft JhengHei'">即时通讯服务</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">网上媒体服务</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">互动娱乐服务</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">社交网络服务</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">支付服务</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">互联网搜索服务</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">位置和地图服务</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">应用软件和服务</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">数据管理软件和服务</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">网上广告服务</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >互联网金融及其他社交媒体</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">娱乐</span
      ><span style="font-family: 'MS Gothic'">、、</span
      ><span style="font-family: 'Microsoft JhengHei'">电子商务</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >资讯及通讯软件和服务</span
      ><span style="font-family: 'Courier New'">；</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> ②</span
      ><span style="font-family: 'Microsoft JhengHei'">第三方商品和服务</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">包括但不限于</span
      ><span style="font-family: 'Courier New'">：</span
      ><span style="font-family: 'Microsoft JhengHei'">互联网服务</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">食物和餐饮</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">体育</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">音乐</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">电影</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">电视</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >现场表演和其他艺术和娱乐</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">书册</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">杂志和其他刊物</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">服装和配饰</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">珠宝</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">化妆品</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">个人健康和卫生</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">电子</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">收藏品</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">家用器皿</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">电器</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">家居装饰和摆设</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">宠物</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">汽车</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">酒店</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">交通和旅游</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">银行</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">保险及金融服务</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">积分和奖励计划</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >以及我们认为可能与您相关的其他商品和服务</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> （2）</span
      ><span style="font-family: 'Microsoft JhengHei'">向您发出通知</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Microsoft JhengHei'">我们可能在必需时</span
      ><span style="font-family: 'Courier New'">（</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >例如当我们由于系统维护而暂停某一单项服务</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">变更</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >终止提供某一单项服务时</span
      ><span style="font-family: 'Courier New'">）</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >向您发出与服务有关的通知</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'">   </span
      ><span style="font-family: 'Microsoft JhengHei'"
        >如您不希望继续接收我们推送的消息</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >您可要求我们停止推送</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">例如</span
      ><span style="font-family: 'Courier New'">：</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >根据短信退订指引要求我们停止发送推广短信</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >或在移动端设备中进行设置</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >不再接收我们推送的消息</span
      ><span style="font-family: 'Courier New'">；</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >但我们依法律规定或单项服务的服务协议约定发送消息的情形除外</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> 4</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">为您提供安全保障</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> （</span
      ><span style="font-family: 'Microsoft JhengHei'">用于保障</span
      ><span style="font-family: 'Courier New'">APP</span
      ><span style="font-family: 'Microsoft JhengHei'">内信息安全</span
      ><span style="font-family: 'Courier New'">/</span
      ><span style="font-family: 'Microsoft JhengHei'">学习</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">娱乐隐私保障</span
      ><span style="font-family: 'Courier New'">/</span
      ><span style="font-family: 'Microsoft JhengHei'">等合法途径</span
      ><span style="font-family: 'Courier New'">）</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'">   </span
      ><span style="font-family: 'Microsoft JhengHei'"
        >为提高您使用我们及合作伙伴提供服务的安全性</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >保护您或其他用户或公众的人身财产安全免遭侵害</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">更好地预防钓鱼网站</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">欺诈</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">网络漏洞</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">计算机病毒</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">网络攻击</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">网络侵入等安全风险</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >更准确地识别违反法律法规或洋葱日语服务相关协议规则的情况</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">我们可能会收集</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >使用或整合您的账户信息</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">交易信息</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">设备信息</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >日志信息以及我们关联公司</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >合作伙伴取得您授权或依据法律共享的信息</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >来综合判断您账户及交易风险</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">进行身份验证</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">检测及防范安全事件</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >并依法采取必要的记录</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">审计</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">分析</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">处置措施</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> 5</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">改进我们的服务</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> （</span
      ><span style="font-family: 'Microsoft JhengHei'">用于</span
      ><span style="font-family: 'Courier New'">APP</span
      ><span style="font-family: 'Microsoft JhengHei'">用户功能使用统计</span
      ><span style="font-family: 'Courier New'">/</span
      ><span style="font-family: 'Microsoft JhengHei'">活动</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >学习效果统计等合法途径</span
      ><span style="font-family: 'Courier New'">）</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'">   </span
      ><span style="font-family: 'Microsoft JhengHei'"
        >我们可能将通过某一项洋葱日语服务所收集的信息</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">用于我们的其他服务</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Microsoft JhengHei'">例如</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >在您使用某一项洋葱日语服务时所收集的您的信息</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >可能在另一项洋葱日语服务中用于向您提供特定内容或向您展示与您相关的</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">而非普遍推送的信息</span
      ><span style="font-family: 'Courier New'">；</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >我们可能让您参与有关洋葱日语服务的调查</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >帮助我们改善现有服务或设计新服务</span
      ><span style="font-family: 'Courier New'">；</span
      ><span style="font-family: 'Microsoft JhengHei'">同时</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >我们可能将您的信息用于软件更新</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'">   </span
      ><span style="font-family: 'Microsoft JhengHei'">您了解并同意</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">在收集您的信息后</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >我们将通过技术手段对数据进行去标识化处理</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >去标识化处理的信息将无法识别您的身份</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >在此情况下我们有权使用已经去标识化的信息</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >对用户数据库进行分析并予以商业化的利用</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> 6</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">其他用途</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> （</span
      ><span style="font-family: 'Microsoft JhengHei'">用于维护</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >支持国家网络信息安全管理</span
      ><span style="font-family: 'Courier New'">/</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >非法信息传播管控等合法途径</span
      ><span style="font-family: 'Courier New'">）</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'">   </span
      ><span style="font-family: 'Microsoft JhengHei'">请您注意</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >如果我们要将您的信息用于本</span
      ><span style="font-family: 'MS Gothic'">《</span
      ><span style="font-family: 'Microsoft JhengHei'">隐私政策</span
      ><span style="font-family: 'MS Gothic'">》</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >中未载明的其他用途或额外收集未提及的其他信息</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >我们会另行事先请您同意</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Microsoft JhengHei'">一旦您同意</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >该等额外用途将视为本</span
      ><span style="font-family: 'MS Gothic'">《</span
      ><span style="font-family: 'Microsoft JhengHei'">隐私政策</span
      ><span style="font-family: 'MS Gothic'">》</span
      ><span style="font-family: 'Microsoft JhengHei'">的一部分</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >该等额外信息也将适用本</span
      ><span style="font-family: 'MS Gothic'">《</span
      ><span style="font-family: 'Microsoft JhengHei'">隐私政策</span
      ><span style="font-family: 'MS Gothic'">》。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> 7</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">征得授权同意的例外</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'">   </span
      ><span style="font-family: 'Microsoft JhengHei'"
        >根据相关法律法规规定</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >以下情形中收集您的信息无需征得您的授权同意</span
      ><span style="font-family: 'Courier New'">：</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> （1）</span
      ><span style="font-family: 'Microsoft JhengHei'">与国家安全</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">国防安全有关的</span
      ><span style="font-family: 'Courier New'">；</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> （2）</span
      ><span style="font-family: 'Microsoft JhengHei'">与公共安全</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">公共卫生</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">重大公共利益有关的</span
      ><span style="font-family: 'Courier New'">；</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> （3）</span
      ><span style="font-family: 'Microsoft JhengHei'">与犯罪侦查</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">起诉</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >审判和判决执行等有关的</span
      ><span style="font-family: 'Courier New'">；</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> （4）</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >出于维护信息主体或其他个人的生命</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >财产等重大合法权益但又很难得到您本人同意的</span
      ><span style="font-family: 'Courier New'">；</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> （5）</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >所收集的信息是您自行向社会公众公开的</span
      ><span style="font-family: 'Courier New'">；</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> （6）</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >从合法公开披露的信息中收集信息的</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">如合法的新闻报道</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">政府信息公开等渠道</span
      ><span style="font-family: 'Courier New'">；</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> （7）</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >根据您的要求签订合同所必需的</span
      ><span style="font-family: 'Courier New'">；</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> （8）</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >用于维护洋葱日语服务的安全稳定运行所必需的</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">例如发现</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >处置产品或服务的故障</span
      ><span style="font-family: 'Courier New'">；</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> （9）</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >为合法的新闻报道所必需的</span
      ><span style="font-family: 'Courier New'">；</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> （10）</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >学术研究机构基于公共利益开展统计或学术研究所必要</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >且对外提供学术研究或描述的结果时</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >对结果中所包含的信息进行去标识化处理的</span
      ><span style="font-family: 'Courier New'">；</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> （11）</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >法律法规规定的其他情形</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> 8</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">您理解并同意</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >部分单项服务可能需要您在您的设备中开启特定的访问权限</span
      ><span style="font-family: 'Courier New'">（</span
      ><span style="font-family: 'Microsoft JhengHei'">例如您的位置信息</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">摄像头</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">相册</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">麦克风</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">通讯录及</span
      ><span style="font-family: 'Courier New'">/</span
      ><span style="font-family: 'Microsoft JhengHei'">或日历</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">存储权限</span
      ><span style="font-family: 'Courier New'">），</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >以实现这些权限所涉及信息的收集和使用</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >当您需要关闭该功能时</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >大多数移动设备都会支持您的这项需求</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >具体方法请参考或联系您移动设备的服务商或生产商</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Microsoft JhengHei'">请您注意</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >您开启任一权限即代表您授权我们可以收集和使用相关信息来为您提供对应服务</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >您一旦关闭任一权限即代表您取消了授权</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >我们将不再基于对应权限继续收集和使用相关信息</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >也无法为您提供该权限所对应的服务</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Microsoft JhengHei'">但是</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >您关闭权限的决定不会影响此前基于您的授权所进行的信息收集及使用</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> 9</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">有关敏感信息的提示</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'">   </span
      ><span style="font-family: 'Microsoft JhengHei'"
        >以上由您提供或我们收集您的信息中</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >可能包含您的个人敏感信息</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">例如银行账号</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">交易和消费记录</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">虚拟财产信息</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">系统账号</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >邮箱地址及其有关的密码</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">电话号码</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">网页浏览记录</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">位置信息</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >请您谨慎并留意个人敏感信息</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >您同意您的个人敏感信息我们可以按本</span
      ><span style="font-family: 'MS Gothic'">《</span
      ><span style="font-family: 'Microsoft JhengHei'">隐私政策</span
      ><span style="font-family: 'MS Gothic'">》</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >所述的目的和方式来处理</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">第二章</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'"
        >如何使用您的个人信息</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'">   </span
      ><span style="font-family: 'Microsoft JhengHei'"
        >在收集您的个人信息后</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >洋葱日语将根据如下规则使用您的个人信息</span
      ><span style="font-family: 'Courier New'">：</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">一</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">会根据本</span
      ><span style="font-family: 'MS Gothic'">《</span
      ><span style="font-family: 'Microsoft JhengHei'">隐私政策</span
      ><span style="font-family: 'MS Gothic'">》</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >的约定并为实现洋葱日语的产品或服务功能对所收集的个人信息进行使用</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">二</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">请您注意</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >对于您在使用洋葱日语的产品或服务时所提供的所有个人信息</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >除非您删除或通过相关设置拒绝我们收集</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >否则您将在使用产品或服务期间</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >账号注销前持续授权我们使用</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">但</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">在您注销账号时</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >洋葱日语将停止使用并删除您的个人信息</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">三</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >洋葱日语会对旗下产品或服务使用情况进行统计</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >并可能会与公众或第三方共享这些统计信息</span
      ><span style="font-family: 'Courier New'">， </span
      ><span style="font-family: 'Microsoft JhengHei'"
        >以展示洋葱日语产品或服务的整体使用趋势</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >但这些统计信息不会包含您的任何身份识别信息</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">四</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >当洋葱日语要将您的个人信息用于本政策未载明的其它用途时</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >或将基于特定目的收集而来的信息用于其他目的时</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >洋葱日语会主动事先征求您的明示同意</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">第三章</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">如何分享</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >转让或披露您的的个人信息</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">一</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">分享</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">除以下情形外</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">未经您同意</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >我们不会与洋葱日语之外的任何第三方分享您的信息</span
      ><span style="font-family: 'Courier New'">：</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> 1</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">向您提供我们的服务</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >我们可能向合作伙伴及其他第三方分享您的信息</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >以实现您需要的核心功能或提供您需要的服务</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">例如</span
      ><span style="font-family: 'Courier New'">：</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >向物流服务商提供对应的订单信息</span
      ><span style="font-family: 'Courier New'">；</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> 2</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >维护和改善我们的服务</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >我们可能向合作伙伴及其他第三方分享您的信息</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >以帮助我们为您提供更有针对性</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">更完善的服务</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">例如</span
      ><span style="font-family: 'Courier New'">：</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >代表我们发出电子邮件或推送通知的通讯服务提供商等</span
      ><span style="font-family: 'Courier New'">；</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> 3</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">实现本</span
      ><span style="font-family: 'MS Gothic'">《</span
      ><span style="font-family: 'Microsoft JhengHei'">隐私政策</span
      ><span style="font-family: 'MS Gothic'">》</span
      ><span style="font-family: 'Microsoft JhengHei'">第一章</span
      ><span style="font-family: 'Courier New'">“</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >如何收集您的个人信息</span
      ><span style="font-family: 'Courier New'">”</span
      ><span style="font-family: 'Microsoft JhengHei'">部分所述目的</span
      ><span style="font-family: 'Courier New'">；</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> 4</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">履行我们在本</span
      ><span style="font-family: 'MS Gothic'">《</span
      ><span style="font-family: 'Microsoft JhengHei'">隐私政策</span
      ><span style="font-family: 'MS Gothic'">》</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >或我们与您达成的其他协议中的义务和行使我们的权利</span
      ><span style="font-family: 'Courier New'">；</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> 5</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >向委托我们进行推广的合作伙伴等第三方共享</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >目的是为了使该等委托方了解推广的覆盖面和有效性</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >比如我们可以告知该委托方有多少人看了他们的推广信息或在看到这些信息后购买了委托方的商品</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >或者向他们提供不能识别个人身份的统计信息</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >帮助他们了解其受众或顾客</span
      ><span style="font-family: 'Courier New'">；</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> 6</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >在法律法规允许的范围内</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">为了遵守法律</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >维护我们及我们的关联方或合作伙伴</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >您或其他洋葱日语用户或社会公众利益</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">财产或安全免遭损害</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >比如为防止欺诈等违法活动和减少信用风险</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >我们可能与其他公司和组织交换信息</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Microsoft JhengHei'">不过</span
      ><span style="font-family: 'Courier New'">,</span
      ><span style="font-family: 'Microsoft JhengHei'">这并不包括违反本</span
      ><span style="font-family: 'MS Gothic'">《</span
      ><span style="font-family: 'Microsoft JhengHei'">隐私政策</span
      ><span style="font-family: 'MS Gothic'">》</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >中所作的承诺而为获利目的出售</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">出租</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >共享或以其它方式披露的信息</span
      ><span style="font-family: 'Courier New'">；</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> 7</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">应您合法需求</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >协助处理您与他人的纠纷或争议</span
      ><span style="font-family: 'Courier New'">；</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> 8</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >根据与您签署的单项服务协议</span
      ><span style="font-family: 'Courier New'">（</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >包括在线签署的电子协议以及相应的平台规则</span
      ><span style="font-family: 'Courier New'">）</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >或其他的法律文件约定所提供</span
      ><span style="font-family: 'Courier New'">；</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> 9</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">基于学术研究而提供</span
      ><span style="font-family: 'Courier New'">；</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> 10</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >基于符合法律法规的社会公共利益而提供</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> 11</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">使用</span
      ><span style="font-family: 'Courier New'">SDK</span
      ><span style="font-family: 'Microsoft JhengHei'">名称</span
      ><span style="font-family: 'Courier New'">：</span
      ><span style="font-family: 'Microsoft JhengHei'">友盟</span
      ><span style="font-family: 'Courier New'">SDK;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">服务类型</span
      ><span style="font-family: 'Courier New'">：</span
      ><span style="font-family: 'Microsoft JhengHei'">统计分析用户行为</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >为用户更好的提供服务</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >收集崩溃信息持续优化应用</span
      ><span style="font-family: 'Courier New'">;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">集个人信息类型</span
      ><span style="font-family: 'Courier New'">：</span
      ><span style="font-family: 'Microsoft JhengHei'">设备信息</span
      ><span style="font-family: 'Courier New'"
        >（IMEI/MAC/Android ID/IDFA/OpenUDID/GUID/SIM </span
      ><span style="font-family: 'Microsoft JhengHei'">卡</span
      ><span style="font-family: 'Courier New'"> IMSI </span
      ><span style="font-family: 'Microsoft JhengHei'">信息等</span
      ><span style="font-family: 'Courier New'">）;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">隐私权政策链接</span
      ><span style="font-family: 'Courier New'"
        >：https://www.umeng.com/page/policy;</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">产品集成友盟</span
      ><span style="font-family: 'Courier New'">+SDK</span
      ><span style="font-family: 'Microsoft JhengHei'">及推送通道</span
      ><span style="font-family: 'Courier New'">SDK（</span
      ><span style="font-family: 'Microsoft JhengHei'">如小米</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">华为</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Courier New'">oppo</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Courier New'">vivo</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">魅族等</span
      ><span style="font-family: 'Courier New'">），</span
      ><span style="font-family: 'Microsoft JhengHei'">推送通道</span
      ><span style="font-family: 'Courier New'">SDK</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >需要收集采集设备标识符</span
      ><span style="font-family: 'Courier New'"
        >（IMEI/MAC/Android ID/IDFA/OpenUDID/GUID/SIM </span
      ><span style="font-family: 'Microsoft JhengHei'">卡</span
      ><span style="font-family: 'Courier New'"> IMSI </span
      ><span style="font-family: 'Microsoft JhengHei'">信息等</span
      ><span style="font-family: 'Courier New'">），</span
      ><span style="font-family: 'Microsoft JhengHei'">用于唯一标识设备</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >以便向用户设备推送消息</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >采集地理位置甄别推送通道</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >提高消息推送的区域覆盖率</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'">   </span
      ><span style="font-family: 'Microsoft JhengHei'">我们仅会出于合法</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">正当</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">必要</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">特定</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >明确的目的共享您的信息</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >对我们与之共享信息的公司</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">组织和个人</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >我们会与其签署严格的保密协定</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >要求他们按照我们的说明</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">本</span
      ><span style="font-family: 'MS Gothic'">《</span
      ><span style="font-family: 'Microsoft JhengHei'">隐私政策</span
      ><span style="font-family: 'MS Gothic'">》</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >以及其他任何相关的保密和安全措施来处理信息</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">二</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">转让</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> 1</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >随着我们业务的持续发展</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">我们有可能进行合并</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">收购</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >资产转让或类似的交易</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >而您的信息有可能作为此类交易的一部分而被转移</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >我们会要求新的持有您信息的公司</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">组织继续受本</span
      ><span style="font-family: 'MS Gothic'">《</span
      ><span style="font-family: 'Microsoft JhengHei'">隐私政策</span
      ><span style="font-family: 'MS Gothic'">》</span
      ><span style="font-family: 'Microsoft JhengHei'">的约束</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">否则</span
      ><span style="font-family: 'Courier New'">,</span
      ><span style="font-family: 'Microsoft JhengHei'">我们将要求该公司</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >组织重新向您征求授权同意</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> 2</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >在获得您的明确同意后</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >我们会向其他方转让您的信息</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">三</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">披露</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'"
        >我们仅会在以下情况下</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >且采取符合业界标准的安全防护措施的前提下</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">才会披露您的信息</span
      ><span style="font-family: 'Courier New'">：</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> 1</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">根据您的需求</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >在您明确同意的披露方式下披露您所指定的信息</span
      ><span style="font-family: 'Courier New'">；</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> 2</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">根据法律</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">法规的要求</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >强制性的行政执法或司法要求所必须提供您信息的情况下</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >我们可能会依据所要求的信息类型和披露方式披露您的信息</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >在符合法律法规的前提下</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >当我们收到上述披露信息的请求时</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >我们会要求接收方必须出具与之相应的法律文件</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">如传票或调查函</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Microsoft JhengHei'">我们坚信</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >对于要求我们提供的信息</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >应该在法律允许的范围内尽可能保持透明</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >我们对所有的请求都进行了慎重的审查</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >以确保其具备合法依据</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >且仅限于执法部门因特定调查目的且有合法权利获取的数据</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">四</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">分享</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">转让</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >披露信息时事先征得授权同意的例外</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">以下情形中</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">分享</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">转让</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >披露您的信息无需事先征得您的授权同意</span
      ><span style="font-family: 'Courier New'">：</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> 1</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">与国家安全</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">国防安全有关的</span
      ><span style="font-family: 'Courier New'">；</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> 2</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">与公共安全</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">公共卫生</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">重大公共利益有关的</span
      ><span style="font-family: 'Courier New'">；</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> 3</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">与犯罪侦查</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">起诉</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >审判和判决执行等司法或行政执法有关的</span
      ><span style="font-family: 'Courier New'">；</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> 4</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >出于维护您或其他个人的生命</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >财产等重大合法权益但又很难得到本人同意的</span
      ><span style="font-family: 'Courier New'">；</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> 5</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >您自行向社会公众公开的信息</span
      ><span style="font-family: 'Courier New'">；</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> 6</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >从合法公开披露的信息中收集信息的</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">如合法的新闻报道</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">政府信息公开等渠道</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'">   </span
      ><span style="font-family: 'Microsoft JhengHei'">根据法律规定</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">共享</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >转让经去标识化处理的信息</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >且确保数据接收方无法复原并重新识别信息主体的</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >不属于信息的对外共享</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">转让及公开披露行为</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >对此类数据的保存及处理将无需另行向您通知并征得您的同意</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">第四章</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">如何使用</span
      ><span style="font-family: 'Courier New'">Cookie</span
      ><span style="font-family: 'Microsoft JhengHei'">和同类技术</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">一</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >为了更好地向您提供产品或服务</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >洋葱日语使用了若干自动数据收集工具</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">目前包括</span
      ><span style="font-family: 'Courier New'">Flash Cookies</span
      ><span style="font-family: 'Microsoft JhengHei'">和内嵌</span
      ><span style="font-family: 'Courier New'">Web</span
      ><span style="font-family: 'Microsoft JhengHei'">链接</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Microsoft JhengHei'">借助这些技术</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >洋葱日语能够实时跟踪您的学习进度</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">并了解您的偏好信息</span
      ><span style="font-family: 'Courier New'">， </span
      ><span style="font-family: 'Microsoft JhengHei'">从而为您提供更优质</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">更个性化的用户体验</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >洋葱日语不会将上述技术用于本政策所述目的之外的任何用途</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">二</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">请您理解</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >洋葱日语的某些服务只能通过使用上述技术才可得到实现</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >如您的设备或相关程序允许</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">您可以通过改变设置</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >或通过访问提供商的网页等合适的方式</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >来关闭或删除上述技术</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >但这一举动可能会影响您使用洋葱日语产品或服务</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">第六章</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'"
        >如何保护您的个人信息</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">一</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >洋葱日语非常重视个人信息安全</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >并会采取一切合理可行的措施</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >持续保护您的个人信息</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >以防其他人在未经授权的情况下访问</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >篡改或披露洋葱日语收集的您的个人信息</span
      ><span style="font-family: 'Courier New'">：</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> 1</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >洋葱日语已采用符合行业标准的安全防护措施来保护您的个人信息</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >防止数据遭到未经授权的访问</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">公开披露</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">使用</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">修改</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">损坏或丢失</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'"
        >我们会采取一切合理可行的措施</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">保护您的个人信息</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Microsoft JhengHei'">例如</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >在您的移动设备与洋葱日语产品或服务之间交换数据时进行</span
      ><span style="font-family: 'Courier New'">SSL</span
      ><span style="font-family: 'Microsoft JhengHei'">加密保护</span
      ><span style="font-family: 'Courier New'">；</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >我们会使用受信赖的保护机制防止数据遭到恶意攻击</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> 2</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >洋葱日语仅允许有必要知晓的人员访问相关个人信息</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >并为此设置了严格的访问权限控制和监控机制</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >洋葱日语同时要求可能接触到您个人信息的所有人员履行相应的保密义务</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >如果未能履行这些义务</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >可能会被追究法律责任或被终止与洋葱日语的相应法律关系</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> 3</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >保护用户个人信息是洋葱日语的一项基本原则</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >洋葱日语将会采取合理的措施保护用户的个人信息</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >除法律法规规定的情形外</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >未经用户许可洋葱日语不会向第三方公开</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">透露用户个人信息</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >洋葱日语对相关信息采用专业加密存储与传输方式</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >保障用户个人信息的安全</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> 4</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >你在注册帐号或使用本服务的过程中</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >需要提供一些必要的信息</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">例如</span
      ><span style="font-family: 'Courier New'">：</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >为向你提供帐号注册服务或进行用户身份识别</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >需要你填写手机号码等</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >若国家法律法规或政策有特殊规定的</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >你需要提供真实的身份信息</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >若你提供的信息不完整</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >则无法使用本服务或在使用过程中受到限制</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> 5</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">一般情况下</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">你可随时浏览</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">修改自己提交的信息</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >但出于安全性和身份识别</span
      ><span style="font-family: 'Courier New'">（</span
      ><span style="font-family: 'Microsoft JhengHei'">如号码申诉服务</span
      ><span style="font-family: 'Courier New'">）</span
      ><span style="font-family: 'Microsoft JhengHei'">的考虑</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >你可能无法修改注册时提供的初始注册信息及其他验证信息</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Courier New'"> 6</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >洋葱日语将运用各种安全技术和程序建立完善的管理制度来保护你的个人信息</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >以免遭受未经授权的访问</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">使用或披露</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> 7</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">未经你的同意</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >洋葱日语不会向洋葱日语以外的任何公司</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >组织和个人披露你的个人信息</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >但法律法规另有规定的除外</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">二</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >洋葱日语会采取一切合理可行的措施</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >确保未收集无关的信息</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">三</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">请您理解</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >由于技术的限制以及可能存在的各种恶意手段</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">在互联网行业</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >即便我们竭尽所能加强安全措施</span
      ><span style="font-family: 'Courier New'">， </span
      ><span style="font-family: 'Microsoft JhengHei'"
        >但也不可能始终保证信息百分之百的绝对安全</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Microsoft JhengHei'">您需要了解和知悉</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >您接入洋葱日语的产品或服务所用的系统和通讯网络</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >有可能因我们可控范围外的其他因素而出现问题</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">在此情形下</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >我们会依法尽力协助解决</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">四</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >如不幸发生信息安全事故</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >我们将按照法律法规的要求</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">及时向您告知</span
      ><span style="font-family: 'Courier New'">：</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >安全事件的基本情况和可能的影响</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >我们已采取或将要采取的处置措施</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >您可自主防范和降低风险的建议等</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >我们同时将及时将事件相关情况以邮件</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">信函</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">电话</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'"
        >推送通知等适合的方式告知您</span
      ><span style="font-family: 'Courier New'">；</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >难以逐一告知信息主体时</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">我们会采取合理</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">有效的方式发布公告</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Microsoft JhengHei'">同时</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >我们还将按照监管部门要求</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >主动上报信息安全事件的处置情况</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">第七章</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'"
        >如何保存您的个人信息</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">一</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">保存期限</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> （</span
      ><span style="font-family: 'Microsoft JhengHei'">用于</span
      ><span style="font-family: 'Courier New'">APP</span
      ><span style="font-family: 'Microsoft JhengHei'">登录的账号保存期限</span
      ><span style="font-family: 'Courier New'">/</span
      ><span style="font-family: 'Microsoft JhengHei'">评论保留时间等</span
      ><span style="font-family: 'Courier New'">）</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> 1</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'"
        >在用户使用洋葱日语产品或服务期间</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >洋葱日语会持续保存用户的个人信息</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> 2</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >洋葱日语承诺始终按照法律的规定在合理必要期限内存储您个人信息</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">对于日志信息</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >记录备份等信息为您注销账号后</span
      ><span style="font-family: 'Courier New'">180</span
      ><span style="font-family: 'Microsoft JhengHei'">天</span
      ><span style="font-family: 'Courier New'"> （</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >但根据法律规定有最短保存期限要求的</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >则我们会保存法律要求的最短期限</span
      ><span style="font-family: 'Courier New'">）；</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >交易信息为交易完成日起三年或您注销账号后</span
      ><span style="font-family: 'Courier New'">180</span
      ><span style="font-family: 'Microsoft JhengHei'">天</span
      ><span style="font-family: 'Courier New'">（</span
      ><span style="font-family: 'Microsoft JhengHei'">以较长者为准</span
      ><span style="font-family: 'Courier New'">）</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">在超出上述期限后</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >我们会对您的相关信息进行删除或匿名化处理</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">二</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">保存地域</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> （</span
      ><span style="font-family: 'Microsoft JhengHei'">用于</span
      ><span style="font-family: 'Courier New'">APP</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >登录短信接收等合法途径</span
      ><span style="font-family: 'Courier New'">）</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> 1</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >您的个人信息将全部被存储于中华人民共和国境内</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> 2</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >目前我们不存在向境外提供个人信息的场景</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">第八章</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">管理</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >查看或删除您的个人信息</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'">   </span
      ><span style="font-family: 'Microsoft JhengHei'"
        >洋葱日语非常尊重您对自己的个人信息所享有的权利</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >我们保障您对个人信息所享有的访问</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">更正</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">删除</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">管理等权利</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >您的信息将会合法规范用于洋葱日语</span
      ><span style="font-family: 'Courier New'">APP</span
      ><span style="font-family: 'Microsoft JhengHei'">用户信息管理</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >保障应用内的信息合法传播</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">一</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >访问和更正您的个人信息</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> （</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >用于维护您的信息设置符合法律相关规定等合法途径</span
      ><span style="font-family: 'Courier New'">）</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> 1</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >除法律法规另有规定之外</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >您有权行使数据访问权</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >当您发现我们处理关于您的个人信息有错误或者您有其他修改</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">补充需求时</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >您也有权要求我们或自行予以更正</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >您行使数据访问和更正权的方式包括但不限于</span
      ><span style="font-family: 'Courier New'">：</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> （1）</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >如果您希望访问或修改您在洋葱日语产品或服务中的账号信息</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">包括头像</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">昵称</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">性别</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">生日等</span
      ><span style="font-family: 'Courier New'">， </span
      ><span style="font-family: 'Microsoft JhengHei'"
        >您可以通过访问我们的产品或服务进行操作</span
      ><span style="font-family: 'Courier New'">（</span
      ><span style="font-family: 'Microsoft JhengHei'">可通过点击</span
      ><span style="font-family: 'Courier New'">“</span
      ><span style="font-family: 'Microsoft JhengHei'">我</span
      ><span style="font-family: 'Courier New'">”，</span
      ><span style="font-family: 'Microsoft JhengHei'">进入个人主页后</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">点击</span
      ><span style="font-family: 'Courier New'">“</span
      ><span style="font-family: 'Microsoft JhengHei'">编辑</span
      ><span style="font-family: 'Courier New'">”</span
      ><span style="font-family: 'Microsoft JhengHei'">功能后进行操作</span
      ><span style="font-family: 'Courier New'">）</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> （2）</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >如洋葱日语的产品或服务中提供发表话题</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">参与讨论</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >留言等功能并使得您有机会通过这些服务公开或提供的个人信息的</span
      ><span style="font-family: 'Courier New'">， </span
      ><span style="font-family: 'Microsoft JhengHei'"
        >则洋葱日语在提供前述服务的同时会提供相应的功能确保您可以再次访问或删除您在前述服务过程中公开或提供的个人信息</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> 2</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >您有权知悉通过洋葱日语获得您的个人信息的第三方的身份或类型</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >您可以通过本政策第一章及相关章节了解第三方的身份或类型</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">二</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >删除您的个人信息及撤回已同意的授权</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> 1</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">在以下情形中</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >您可以向洋葱日语提出删除个人信息的请求</span
      ><span style="font-family: 'Courier New'">：</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> （1）</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >如果洋葱日语处理个人信息的行为违反相关的法律法规</span
      ><span style="font-family: 'Courier New'">；</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> （2）</span
      ><span style="font-family: 'Microsoft JhengHei'">如果洋葱日语收集</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">使用您的个人信息</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">却未征得您的同意</span
      ><span style="font-family: 'Courier New'">；</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> （3）</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >如果洋葱日语处理个人信息的行为违反了与您的约定或法律的规定</span
      ><span style="font-family: 'Courier New'">；</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> （4）</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >如果您不再使用洋葱日语的产品或服务</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >或者您注销了相关账号</span
      ><span style="font-family: 'Courier New'">；</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> （5）</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >如果洋葱日语不再为您提供产品或服务</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> 2</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >您有权向洋葱日语撤回您此前作出的有关同意收集</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >使用您的个人信息的授权</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Microsoft JhengHei'">当您撤回同意后</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >我们将不再处理您的相关个人信息</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Microsoft JhengHei'">但您撤回同意的决定</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >不会影响此前基于您的授权而开展的个人信息处理活动</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> 3</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >您可以通过删除相关个人信息的方式撤回您此前就特定个人信息而对我们作出的同意授权</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">三</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">注销账号</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> （</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >在您的账号活动出现违反用户隐私协议或者国家法律法规的情况下执行</span
      ><span style="font-family: 'Courier New'">）</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> 1</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >您有权随时注销您在洋葱日语产品或服务中注册的账号</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >为保障账号及财产安全</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >您需要通过客户服务或本</span
      ><span style="font-family: 'MS Gothic'">《</span
      ><span style="font-family: 'Microsoft JhengHei'">隐私条款</span
      ><span style="font-family: 'MS Gothic'">》</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >第十章载明的联系方式向洋葱日语提出您的账号注销请求</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >洋葱日语将在与您核实相关信息后对您的请求作出响应</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> 2</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">请您注意</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >注销洋葱日语相关产品或服务账号是不可恢复的操作</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Microsoft JhengHei'">在注销账号之后</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >我们将停止为您提供产品或服务</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >并将删除该账号项下的您的个人信息</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >除非法律法规另有规定</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">四</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >约束信息系统自动决策</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'">   </span
      ><span style="font-family: 'Microsoft JhengHei'"
        >在洋葱日语仅依据信息系统</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >算法等在内的非人工自动决策机制做出决定</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >并且这些决定显著影响您的合法权益的情况下</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >您有权要求洋葱日语做出解释</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >洋葱日语也将提供适当的救济方式</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">五</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">获取个人信息副本</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'">   </span
      ><span style="font-family: 'Microsoft JhengHei'">根据您的请求</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >洋葱日语可以向您提供洋葱日语持有的有关您的个人信息副本</span
      ><span style="font-family: 'Courier New'">（</span
      ><span style="font-family: 'Microsoft JhengHei'">如个人基本资料</span
      ><span style="font-family: 'Courier New'">）</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >您可以通过客户服务或本</span
      ><span style="font-family: 'MS Gothic'">《</span
      ><span style="font-family: 'Microsoft JhengHei'">隐私条款</span
      ><span style="font-family: 'MS Gothic'">》</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >第十章载明的联系方式向我们提出请求</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">六</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">响应您的上述请求</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> 1</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'"
        >如果您对洋葱日语在以上列明的有关访问</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">更正</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">删除您的个人信息</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">以及撤回同意</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">注销账号</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >约束信息系统自动决策方法有任何疑问</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >您可以通过客户服务或本</span
      ><span style="font-family: 'MS Gothic'">《</span
      ><span style="font-family: 'Microsoft JhengHei'">隐私政策</span
      ><span style="font-family: 'MS Gothic'">》</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >第十章载明的联系方式与我们取得联系</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> 2</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">对于您合理的请求</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >洋葱日语原则上不收取费用</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">但对多次重复</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">超出合理限度的请求</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >洋葱日语将视情况收取一定成本费用</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Microsoft JhengHei'">对于那些无端重复</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">需要过多技术手段</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >给他人合法权益带来风险或者非常不切实际的请求</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >洋葱日语可能会予以拒绝</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> 3</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">在以下情形下</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >我们可能无法响应您的请求</span
      ><span style="font-family: 'Courier New'">：</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> （1）</span
      ><span style="font-family: 'Microsoft JhengHei'">与国家安全</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">国防安全有关的</span
      ><span style="font-family: 'Courier New'">；</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> （2）</span
      ><span style="font-family: 'Microsoft JhengHei'">与公共安全</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">公共卫生</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">重大公共利益有关的</span
      ><span style="font-family: 'Courier New'">；</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> （3）</span
      ><span style="font-family: 'Microsoft JhengHei'">与犯罪侦查</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">起诉和审判等有关的</span
      ><span style="font-family: 'Courier New'">；</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> （4）</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >有证据表明您存在主观恶意或滥用权利的</span
      ><span style="font-family: 'Courier New'">；</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> （5）</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >响应您的请求将导致其他个人</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >组织的合法权益受到严重损害的</span
      ><span style="font-family: 'Courier New'">；</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> （6） </span
      ><span style="font-family: 'Microsoft JhengHei'">涉及商业秘密的</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">七</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">申诉机制</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> 1</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >洋葱日语已经建立申诉管理机制</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">包括跟踪流程等</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >为了保障账号及财产安全</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >洋葱日语可能会与您核实相关信息</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'"
        >洋葱日语将在收到您的反馈后尽快答复您的请求</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">最长不超过</span
      ><span style="font-family: 'Courier New'">30</span
      ><span style="font-family: 'Microsoft JhengHei'">天</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >若您对答复意见不满意</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >您可以再次通过联系洋葱日语客户进行申诉</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">第九章</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">投诉及建议</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'">   </span
      ><span style="font-family: 'Microsoft JhengHei'"
        >您在使用洋葱日语产品或服务的过程中</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">如果对本</span
      ><span style="font-family: 'MS Gothic'">《</span
      ><span style="font-family: 'Microsoft JhengHei'">隐私政策</span
      ><span style="font-family: 'MS Gothic'">》</span
      ><span style="font-family: 'Microsoft JhengHei'">有任何的疑义或建议</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >或您认为您的个人信息没有得到本</span
      ><span style="font-family: 'MS Gothic'">《</span
      ><span style="font-family: 'Microsoft JhengHei'">隐私政策</span
      ><span style="font-family: 'MS Gothic'">》</span
      ><span style="font-family: 'Microsoft JhengHei'">规定的保护</span
      ><span style="font-family: 'Courier New'">， </span
      ><span style="font-family: 'Microsoft JhengHei'"
        >您可以通过以下方式联系我们</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >我们将真诚地处理您的投诉及建议</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">公司名称</span
      ><span style="font-family: 'Courier New'">：</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >湖南洋葱教育科技有限公司</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">注册</span
      ><span style="font-family: 'Courier New'">/</span
      ><span style="font-family: 'Microsoft JhengHei'">办公地址</span
      ><span style="font-family: 'Courier New'">：</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >湖南省长沙市高新开发区谷苑路</span
      ><span style="font-family: 'Courier New'">229</span
      ><span style="font-family: 'Microsoft JhengHei'">号海凭园</span
      ><span style="font-family: 'Courier New'">8</span
      ><span style="font-family: 'Microsoft JhengHei'">栋</span
      ><span style="font-family: 'Courier New'">301</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">官方客服热线</span
      ><span style="font-family: 'Courier New'">：18570354335</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">第十章</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">本政策如何更新</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">一</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >如洋葱日语产品或服务发生以下变化</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">洋葱日语将及时对本</span
      ><span style="font-family: 'MS Gothic'">《</span
      ><span style="font-family: 'Microsoft JhengHei'">隐私政策</span
      ><span style="font-family: 'MS Gothic'">》</span
      ><span style="font-family: 'Microsoft JhengHei'">进行相应的修订</span
      ><span style="font-family: 'Courier New'">：</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> 1</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >洋葱日语产品或服务所涉业务功能发生变更</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >导致处理个人信息的目的</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">类型</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">使用方式发生变更</span
      ><span style="font-family: 'Courier New'">；</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> 2</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >您参与个人信息处理方面的权利及其行使方式发生重大变化</span
      ><span style="font-family: 'Courier New'">；</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> 3</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'"
        >洋葱日语负责处理您的个人信息安全的部门的联络方式发生变更</span
      ><span style="font-family: 'Courier New'">；</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> 4</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >发生其他可能影响用户个人信息安全或影响用户隐私权利的变更等</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">二</span
      ><span style="font-family: 'MS Gothic'">、《</span
      ><span style="font-family: 'Microsoft JhengHei'">隐私政策</span
      ><span style="font-family: 'MS Gothic'">》</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >修订后洋葱日语会在洋葱日语产品或服务相关界面发布最新版本并以弹窗</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >推送通知等合理的方式告知用户</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">以便用户及时了解</span
      ><span style="font-family: 'MS Gothic'">《</span
      ><span style="font-family: 'Microsoft JhengHei'">隐私政策</span
      ><span style="font-family: 'MS Gothic'">》</span
      ><span style="font-family: 'Microsoft JhengHei'">的最新版本</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">三</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">未经您的明确同意</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >洋葱日语不会削减您基于本</span
      ><span style="font-family: 'MS Gothic'">《</span
      ><span style="font-family: 'Microsoft JhengHei'">隐私政策</span
      ><span style="font-family: 'MS Gothic'">》</span
      ><span style="font-family: 'Microsoft JhengHei'">所享有的权利</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">四</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">如无特殊说明</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">修订后的</span
      ><span style="font-family: 'MS Gothic'">《</span
      ><span style="font-family: 'Microsoft JhengHei'">隐私政策</span
      ><span style="font-family: 'MS Gothic'">》</span
      ><span style="font-family: 'Microsoft JhengHei'">自公布之日起生效</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">第十一章</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">其他</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">一</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >因本政策以及我们处理您个人信息事宜引起的任何争议</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >您可诉至有管辖权的人民法院</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">二</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >如果您认为我们的个人信息处理行为损害了您的合法权益</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >您也可向有关政府部门进行反映</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'"> </span
      ><span style="font-family: 'Microsoft JhengHei'">三</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">在洋葱日语发布本</span
      ><span style="font-family: 'MS Gothic'">《</span
      ><span style="font-family: 'Microsoft JhengHei'">隐私政策</span
      ><span style="font-family: 'MS Gothic'">》</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >或向您提供产品或服务均视为本</span
      ><span style="font-family: 'MS Gothic'">《</span
      ><span style="font-family: 'Microsoft JhengHei'">隐私政策</span
      ><span style="font-family: 'MS Gothic'">》</span
      ><span style="font-family: 'Microsoft JhengHei'">生效</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >洋葱日语停止运营或永久停止提供产品或服务时本</span
      ><span style="font-family: 'MS Gothic'">《</span
      ><span style="font-family: 'Microsoft JhengHei'">隐私政策</span
      ><span style="font-family: 'MS Gothic'">》</span
      ><span style="font-family: 'Microsoft JhengHei'">失效</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Courier New'">&#xa0;</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >第十二章与第三方服务提供商共享您的个人信息</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-indent: 25.2pt;
        font-size: 20pt;
      "
    >
      <span style="width: 10.8pt; text-indent: 0pt; display: inline-block"
        >&#xa0;</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >本业务中部分服务内容由第三方服务提供商提供</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >为此我们需要向第三方服务提供商提供您的部分个人信息</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        ><span class="see" @click="handleSee">点击查看</span>
        我们向合作伙伴及第三方共享您的个人信息的情形</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Microsoft JhengHei'">如我们</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Microsoft JhengHei'"
        >与这些第三方分享您的个人信息</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >我们将采取加密等手段保障您的信息安全</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-indent: 25.2pt;
        font-size: 20pt;
      "
    >
      <span style="width: 10.8pt; text-indent: 0pt; display: inline-block"
        >&#xa0;</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >对我们与之共享个人信息的公司</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Microsoft JhengHei'">组织</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >我们会对其数据安全环境进行合理审查</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >并与其签署严格的数据处理协议</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >要求第三方对您的信息采取足够的保护措施</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >严格遵守相关法律法规</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Microsoft JhengHei'">与监管要求</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-indent: 25.2pt;
        font-size: 20pt;
      "
    >
      <span style="font-family: 'Microsoft JhengHei'"
        >第十三张第三方网站和服务</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-indent: 25.2pt;
        font-size: 20pt;
      "
    >
      <span style="width: 10.8pt; text-indent: 0pt; display: inline-block"
        >&#xa0;</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >我们的隐私政策不适用于第三方提供的产品和服务</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >取决于您所使用的应用商店的产品或服务</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >其中可能包括第三方的产品或服务</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">涉及应用搜索等类型</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >其中一些会以第三方网站的链接形式</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Microsoft JhengHei'">提供</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">还有一些会以</span
      ><span style="font-family: 'Courier New'">SDK</span
      ><span style="font-family: 'MS Gothic'">、</span
      ><span style="font-family: 'Courier New'">API</span
      ><span style="font-family: 'Microsoft JhengHei'">等形式接入</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-indent: 25.2pt;
        font-size: 20pt;
      "
    >
      <span style="width: 10.8pt; text-indent: 0pt; display: inline-block"
        >&#xa0;</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >当您使用这些产品或服务时</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">也可能收集您的信息</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Microsoft JhengHei'">因为</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >我们强烈建议您花时间阅读该第三方的隐私政策</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >就像阅读我们的政策一样</span
      ><span style="font-family: 'MS Gothic'">。</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >我们不对第三方如何使用他们向您收集的个人</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Microsoft JhengHei'">信息负责</span
      ><span style="font-family: 'Courier New'">，</span
      ><span style="font-family: 'Microsoft JhengHei'">也不能控制其使用</span
      ><span style="font-family: 'MS Gothic'">。</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-indent: 25.2pt;
        font-size: 20pt;
      "
    >
      <span style="width: 10.8pt; text-indent: 0pt; display: inline-block"
        >&#xa0;</span
      ><span style="font-family: 'Microsoft JhengHei'"
        >我们的隐私政策不适用通过我们的服务链接的其他网站</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span>
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 20pt">
      <span style="font-family: 'Courier New'">&#xa0;</span>
    </p>
  </div>
</template>

<script>
export default {
  name: "privacy",
  methods: {
    handleSee() {
      this.$router.push("/thirdPrivacy");
    },
  },
};
</script>

<style lang="scss" scoped>
.privacy {
  padding: 10px;
  .see {
    color: blue;
  }
}
</style>